import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import ImageUpload from "../../pages/multiImage";
import ApiClientService from "../../../services/auth/api-client-service";
import moment from "moment";
import UserDialog from "../../pages/Dialog";
import UserSnackbars from "../../pages/Snackbar";
import CustomCheckBox from "../../pages/Checkbox";
import { getAllImageTag, dateFormat, combineNumbersAsDecimal, splitNumber } from "../../../helpers/Helpers";
import ResourceComponent from "./components/ResourceComponent";
import { ActivityContext } from "../../../context/context";
import ItemComponent from "./components/ItemComponent";
import { Spin } from "antd";
import ItemSetComponent from "./components/ItemSetComponent";
import {
    GetItemSelectList,
    GetWhsBatchNumberSelectList,
} from "../../../helpers/SelectHelpers";
import { generateUUID } from "../../../helpers/GuidGeneratorHelper";

function ActivityAddEdit(props) {
    //#region Default definations
    const history = useHistory();
    const defaultEquipmentRow = [{ id: "-1", value: "" }];
    const defaultMaterialRow = [
        { id: Math.floor(Math.random() * 10), value: "" },
    ];
    const defaultEmployeeOptions = [{ label: "", value: "" }];
    const defaultImageTagOptions = [{ label: "", value: "" }];
    const [loading, setLoading] = useState(false);
    let isEndOfDayWaning = false;

    var defaultIssueObject = {
        Id: 0,
        workedDate: "",
        workedTimeStart: "",
        workedTimeEnd: "",
        isCompleted: false,
        isLunchIncluded: false,
        description: "",
        taskData: null,
        materialData: null,
        equipmentData: null,
        serialNumberData: null,
        segmentData: null,
        equipmentStartTime: null,
        equipmentEndTime: null,
        materialQuantity: 0,
    };
    const [navigateData, setNavigateData] = useState(
        props.history.location.state
    );
    const [equipmentRows, setEquipmentRows] = useState(defaultEquipmentRow);
    const [materialRows, setMaterialRows] = useState(defaultMaterialRow);
    const [itemOptions, setItemOptions] = useState([]);
    const [batchNumbers, setBatchNumbers] = useState([]);
    const [issueData, setIssueData] = useState(defaultIssueObject);
    const [employeeOptions, setEmployeeOptions] = useState(
        defaultEmployeeOptions
    );
    const [issueSetItems, setIssueSetItems] = useState(null);
    const [isTeamLead, setIsTeamLead] = useState(false);
    const [taskOptions, setTaskOptions] = useState([]);
    const [items, setItems] = useState([
        {
            selectedItem: {
                label: "None",
                value: 0,
            },
            selectedBatchNumber: null,
            uom: "",
            quantity: 0,
            isBatchNumber: false,
            itemType: "I",
            decimal: 0
        },
    ]);

    const [itemSets, setItemSets] = useState([
        {
            selectedItem: {
                label: "None",
                value: 0,
            },
            selectedBatchNumber: null,
            isBatchNumber: false,
            itemType: "S",
        },
    ]);
    const [resources, setResources] = useState([
        {
            selectedShortName: [],
            startTime: "",
            endTime: "",
        },
    ]);
    const [projectData, setProjectData] = useState([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [variantSnackbar, setVariantSnackbar] = useState("");
    const [messageSnackbar, setMessageSnackbar] = useState("");
    const timeout = 5000;
    const [file, setFile] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [user, setUser] = useState({});
    const [employee, setEmployee] = useState(null);
    const [isEndOfDayChecked, setIsEndOfDayChecked] = useState(false);
    const [isNightShift, setIsNightShift] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [maxDate, setMaxDate] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [minDate, setMinDate] = useState(
        moment(new Date().setDate(new Date().getDate() - 2)).format("YYYY-MM-DD")
    );
    const [imageTagOptions, setImageTagOptions] = useState(
        defaultImageTagOptions
    );
    const [dialogSettings, setDialogSettings] = useState({
        endOfDay: {
            open: false,
            message: "",
            title: "About working time",
        },
        postAction: {
            open: false,
            message:
                "You are adding an activity for the operation you selected, are you sure you want to add it?",
            title: "Activity Add",
        },
        postDraft: {
            open: false,
            title: "Draft Registration Approval",
            message: "Do you confirm to save the draft you entered?",
        },
    });
    //#endregion

    useEffect(() => {
        if (navigateData.params.docStgId > 0) {
            var exceptionObject = {
                projectEntry: navigateData.params.pEntry,
                stgEntry: navigateData.params.stgEntry,
                workingLocation: navigateData.params.workingLocation,
                subWorkingLocation: navigateData.params.subWorkingLocation,
                problemLocation: navigateData.params.problemLocation,
            };
            GetItemSelectList(
                setItemOptions,
                navigateData.params.docStgId,
                exceptionObject
            );
            GetWhsBatchNumberSelectList(
                setBatchNumbers,
                navigateData.params.docStgId
            );
        }
    }, []);

    const getUser = () => {
        ApiClientService.get(process.env.REACT_APP_API_GET_USER, null).then(
            (value) => {
                if (value) {
                    setUser(value.data.data);
                }
            }
        );
    };
    const getEmployeeOptions = async (id) => {
        const data = {
            stageId: id,
        };
        try {
            const response = await ApiClientService.get(
                process.env.REACT_APP_API_GET_STAGE_EMPLOYEE_LIST,
                data
            );
            if (!response) return;
            const employees = response.data.data;

            if (!employees) return;

            const userObject = employees.reduce((acc, employee) => {
                if (employee.user) {
                    acc.push({
                        label: `${employee.user.firstName} ${employee.user.lastName}`,
                        value: employee.user.id,
                    });
                }
                return acc;
            }, []);

            setEmployeeOptions(distinctData(userObject));
        } catch (error) {
            console.error(error);
        }
    };

    const distinctData = (array) => {
        return array.filter(
            (element, index) =>
                array.findIndex((obj) => obj.value === element.value) === index
        );
    };

    //#region Get Issue Data
    const getIssueGetById = (params, arrayObject) => {
        var request = {
            id: params.issueId,
        };
        ApiClientService.get(
            process.env.REACT_APP_API_GET_BY_ID_ISSUE,
            request
        ).then((value) => {
            var data = value.data.data;
            if (!value.data.data) return;
            var itemList = [];
            var filterChildItems = [];
            var resourceList = [];
            var imageObject = [];
            var object = {
                workedDate: moment(data.startDate).format("YYYY-MM-DD"),
                description: data.description,
                workedTimeStart: moment(data.startDate).format("HH:mm"),
                workedTimeEnd: moment(data.endDate).format("HH:mm"),
                isCompleted: data.isCompleted,
                isLunchIncluded: data.isLunchIncluded,
                isNightShift: data.isNightShift,
                taskData: arrayObject.task.find((x) => x.value == data.taskCode),
                stageEmployee: data.stageEmployee,
                userId: data.userId,
                isDraft: data.isDraft,
            };

            setIsTeamLead(false);

            var startDate = moment(object.stageEmployee.workStartTime).format(
                "YYYY-MM-DD"
            );
            var endDate = moment(object.stageEmployee.workEndTime).format(
                "YYYY-MM-DD"
            );

            if (startDate <= object.workedDate && object.workedDate <= endDate) {
                setIsDisabled(false);
                setMinDate(startDate);
            } else {
                setIsDisabled(true);
            }
            if (
                data.stageEmployee == null ||
                data.stageEmployee.haveApprovalAuthority
            )
                setIsTeamLead(false);
            else setIsTeamLead(true);

            getEmployeeOptions(data.stageEmployee.stageId);
            setIsNightShift(data.isNightShift);
            data.issueImages.forEach(function (obj) {
                var f = new File([""], obj.url, {
                    type: "image/png",
                    name: obj.cdnpath,
                    lastModified: obj.createdTime,
                });
                var image = {
                    tag: { label: obj.tag.tag, value: obj.tag.id },
                    description: obj.description,
                    url: "https://geoform.azureedge.net/images/" + obj.cdnpath,
                    image: f,
                    name: obj.cdnpath,
                    isNew: false,
                    verticalDistance: obj.verticalDistance,
                    horizontalDistance: obj.horizontalDistance,
                    verticalUnit: obj.verticalUnit,
                    horizontalUnit: obj.horizontalUnit,
                };
                imageObject.push(image);
            });

            setFile([...file, ...imageObject]);
            setIssueData({ ...issueData, ...object });
            data.issueItems.forEach(function (obj, index) {
                const { quantity, decimal } = splitNumber(obj.quantity);
                if (obj.itemType == "I") {  
                itemList.push({
                        selectedItem: {
                            label: obj.itemName,
                            value: obj.itemCode,
                            batchNumber: obj.itemCode,
                        },
                        selectedBatchNumber: {
                            label: obj.batchNumber,
                            value: obj.batchNumber,
                        },
                        uom: obj.itemInventoryUoM,
                        quantity: quantity,
                        decimal: decimal,
                        isBatchNumber: obj.batchNumber ? true : false,
                    });
                }
            });
        
            setIssueSetItems(data.issueItems);

            data.issueResources.forEach(function (obj, index) {
                if (obj.shortName != null && obj.shortName.trim() != "") {
                    resourceList.push({
                        selectedShortName: { value: obj.shortNameId, label: obj.shortName },
                        startTime: moment(obj.startDate).format("HH:mm"),
                        endTime: moment(obj.endDate).format("HH:mm"),
                    });
                }
            });
            if (filterChildItems.length > 0) setItemSets(filterChildItems);
            if (itemList.length > 0) {
                setItems(itemList);
            }
            if (resourceList.length > 0) {
                setResources(resourceList);
            }

            for (var i = 1; i < Object.keys(resourceList).length; i++) {
                setEquipmentRows((equipmentRows) => [
                    ...equipmentRows,
                    {
                        id: Math.floor(Math.random() * 100),
                        value: "",
                    },
                ]);
            }
            for (var i = 1; i < Object.keys(itemList).length; i++) {
                setMaterialRows((materialRows) => [
                    ...materialRows,
                    {
                        id: Math.floor(Math.random() * 100),
                        value: "",
                    },
                ]);
            }
            setLoading(false);
        });
    };
    //#endregion

    //#region Get Options Data
    const getOptionsData = (params) => {
        setLoading(true);
        getAllImageTag(setImageTagOptions);
        var request = {
            docstgId: params.docStgId,
        };
        ApiClientService.get(
            process.env.REACT_APP_API_GET_DOC_ITEM_LIST,
            request
        ).then((value) => {
            if (!value) return;

            var data = value.data.data[0];
            var arrayEquipment = [];
            var arrayTask = [];
            var arraySegment = [];
            var arraySerialNumber = [];

            //task
            data.stg.tasks.forEach(function (task) {
                if (task != null) {
                    arrayTask.push({
                        value: task.code,
                        label: task.desc,
                    });
                }
            });
            setTaskOptions(arrayTask);

            var arrayObject = {
                segment: arraySegment,
                task: arrayTask,
                seriNo: arraySerialNumber,
                quipment: arrayEquipment,
            };
            if (navigateData.params.action == "update") {
                var dialog = { ...dialogSettings };
                dialog.postAction.message =
                    "You are updating an activity for the operation you selected, are you sure you want to continue?";
                dialog.postAction.title = "Activity Update";
                setDialogSettings(dialog);
                getIssueGetById(navigateData.params, arrayObject);
            }
            setLoading(false);
        });
    };
    //#endregion

    useEffect(() => {
        if (!navigateData) return;
        if (navigateData.params.action != "update") {
            var startDate = moment(
                navigateData.params.stageEmployees[0]?.workStartTime
            ).format("YYYY-MM-DD");
            var endDate = moment(
                navigateData.params.stageEmployees[0]?.workEndTime
            ).format("YYYY-MM-DD");
            if (minDate > endDate) {
                setIsDisabled(true);
            }
            if (minDate <= startDate) setMinDate(startDate);
            if (maxDate >= endDate) setMaxDate(endDate);

            var employeeData = navigateData.params.stageEmployees.filter(
                (x) => x.haveApprovalAuthority
            );
            if (employeeData == null || employeeData.length == 0)
                setIsTeamLead(false);
            else setIsTeamLead(true);
            getEmployeeOptions(navigateData.params.stageId);
        }
    }, []);

    useEffect(() => {
        getOptionsData(navigateData.params);
        localStorage.setItem("Activity", JSON.stringify(navigateData.params));
        getUser();
    }, []);

    //#region StyleSheet
    const textAreaStyle = {
        resize: "none",
        height: "150px",
    };
    //#endregion

    const openDialog = (e = null, type) => {
        if (e != null) e.preventDefault();
        dialogOpenReject(type, true);
    };
    const rejectAction = (e, type) => {
        e.preventDefault();
        dialogOpenReject(type, false);
    };
    function dialogOpenReject(type, isOpen) {
        var dialog = { ...dialogSettings };
        switch (type) {
            case "save":
                dialog.postAction.open = isOpen;
                break;
            case "draft":
                dialog.postDraft.open = isOpen;
                setIsDraft(isOpen);
                break;
            case "endofday":
                dialog.endOfDay.open = isOpen;
                isEndOfDayWaning = false;
                break;
            default:
                dialog.postAction.open = false;
                dialog.postDraft.open = false;
                dialog.endOfDay.open = false;
                break;
        }
        setDialogSettings(dialog);
    }

    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    const imageUpload = (e) => {
        e.preventDefault();
        setLoading(true);
        dialogOpenReject("all", false);
        var objectImages = [];
        var promises = [];
        file.forEach(function (obj, index) {
            var formdata = new FormData();
            formdata.append("image", obj.image, obj.url);
            if (obj.tag.value == "")
                return handleAlert("file tag are required.", "warning");
            if (obj.isNew) {
                promises.push(
                    ApiClientService.post(
                        process.env.REACT_APP_API_IMAGE_UPLOAD,
                        formdata
                    ).then((value) => {
                        objectImages.push({
                            Cdnpath: value.data.value.cdnpath,
                            TagId: obj.tag.value,
                            Description: obj.description,
                            HorizontalDistance: obj.horizontalDistance,
                            VerticalDistance: obj.verticalDistance,
                            VerticalUnit: obj.verticalUnit,
                            HorizontalUnit: obj.horizontalUnit,
                        });
                    })
                );
            } else {
                objectImages.push({
                    Cdnpath: obj.name,
                    TagId: obj.tag.value,
                    HorizontalDistance: obj.horizontalDistance,
                    VerticalDistance: obj.verticalDistance,
                    VerticalUnit: obj.verticalUnit,
                    HorizontalUnit: obj.horizontalUnit,
                });
            }
        });
        Promise.all(promises).then(() => {
            setLoading(false);
            handleSubmit(objectImages);
        });
    };
    const getDefaultEmployee = () => {
        if (employeeOptions[0].label != "" && employeeOptions[0].value != "") {
            var data = [];
            if (!user?.id) {
                getUser();
                return;
            }
            if (navigateData.params.action == "update") {
                data = employeeOptions.find((x) => x.value == issueData.userId);
            } else data = employeeOptions.find((x) => x.value == user?.id);
            if (!employee) {
                setEmployee((employee) => data);
            }
        }
    };
    const changeEmployeeData = (data) => {
        setEmployee((employee) => data);
    };
    const employeeSelectBox = () => {
        getDefaultEmployee();
        return (
            <div className="form-row" key={"issue-form-row"}>
                <div className="col-12 col-sm-4 mb-5" key={"issue-employee-div"}>
                    <label key={"issue-employee-name-label"}>Employee Name</label>
                    <Select
                        key="employee-select-box"
                        value={employee}
                        options={employeeOptions}
                        onChange={(e) => changeEmployeeData(e)}
                        isDisabled={!isTeamLead}
                        isSearchable={false}
                    />
                </div>
            </div>
        );
    };
    const timePickerControl = (start, end) => {
        var diff = moment.duration(
            moment(end, "HH:mm").diff(moment(start, "HH:mm"))
        );
        var message = "";
        if (start == "") message = "start date fields cannot be empty.";
        else if (end == "" && isDraft == false)
            return (message = "end date fields cannot be empty.");
        else if ((diff._data.hours < 0 || diff._data.minutes < 0) && !isNightShift)
            return (message = "The end time cannot be less than the start time.");

        return message;
    };
    const handleAlert = (message, type) => {
        setMessageSnackbar(message);
        setVariantSnackbar(type);
        setOpenSnackbar(true);
        setLoading(false);
        dialogOpenReject("all", false);
    };

    const handleSubmit = (images) => {
        dialogOpenReject("all", false);

        var objectIssueItems = [];
        var objectResourceItems = [];
        if (!isDraft) {
            if (issueData.taskData == null || issueData.taskData.length == 0)
                return handleAlert("Task cannot be empty", "warning");
        }
        var timeMessage = timePickerControl(
            issueData.workedTimeStart,
            issueData.workedTimeEnd
        );
        if (timeMessage.trim() != "")
            return handleAlert("Worked Data " + timeMessage, "warning");
        try {
            for (var i = 0; i < items.length; i++) {
                let quantityItem = combineNumbersAsDecimal(items[i].quantity, items[i].decimal);
                if (
                    items[i].selectedItem == null &&
                    (quantityItem <= 0 || quantityItem == null)
                ) {
                    continue;
                }
                if (items[i].selectedItem == null)
                    return handleAlert("Material data not empty", "warning");
                if (items[i].selectedItem.value == 0) continue;
                else if (quantityItem == null || quantityItem <= 0){
                    return handleAlert("Inventory Uom data not empty", "warning");
                }
                else if (items[i].isBatchNumber && items[i].selectedBatchNumber == null)
                    return handleAlert("Batch Number is not selected", "warning");

                objectIssueItems.push({
                    ItemCode: items[i].selectedItem.value,
                    Quantity: quantityItem,
                    BatchNumber: items[i].selectedBatchNumber?.label,
                    ItemName: items[i].selectedItem.label,
                    ItemInventoryUoM: items[i].uom,
                    ItemType: "I",
                    ProcessID: generateUUID(),
                });
            }
            itemSets.forEach((itemSet) => {
                if (itemSet.selectedItem.value != 0) {
                    const itemSetProcessID = generateUUID();
                    itemSet.childItems.forEach((childItem) => {
                        let quantity = combineNumbersAsDecimal(childItem.quantity, childItem.decimal);
                        if (childItem.quantity) {
                            objectIssueItems.push({
                                ItemCode: childItem.value,
                                Quantity: quantity,
                                BatchNumber: childItem.selectedBatchNumber?.value,
                                ItemName: childItem.label,
                                ItemInventoryUoM: childItem.uom,
                                FatherCode: childItem.fatherCode,
                                ItemType: "S",
                                ProcessID: itemSetProcessID,
                            });
                        }
                    });
                }
            });
            for (var i = 0; i < resources.length; i++) {
                var timeMessage = timePickerControl(
                    resources[i].startTime,
                    resources[i].endTime
                );
                if (
                    resources[i].selectedShortName.length <= 0 &&
                    timeMessage.trim() != ""
                )
                    continue;
                if (!resources[i].selectedShortName.value)
                    return handleAlert("Equipment data not empty", "warning");
                if (timeMessage.trim() != "")
                    return handleAlert("Consumed Materials " + timeMessage, "warning");

                objectResourceItems.push({
                    WorkingLocation: navigateData.params.workingLocation,
                    ShortName: resources[i].selectedShortName.label,
                    ShortNameId: resources[i].selectedShortName.value,
                    StartDate: dateFormat(issueData.workedDate, resources[i].startTime),
                    EndDate: dateFormat(issueData.workedDate, resources[i].endTime),
                });
            }
            var object = {
                Id: navigateData.params.issueId ? navigateData.params.issueId : 0,
                ProjectEntry: navigateData.params.pEntry,
                DocEntry: navigateData.params.docEntry,
                StgEntry: navigateData.params.stgEntry,
                ProjectName: navigateData.params.projectName,
                StageName: navigateData.params.stageName,
                DocNum: navigateData.params.docNum,
                StgDesc: navigateData.params.stgDesc,
                DocStgId: navigateData.params.docStgId,
                TaskCode: issueData.taskData?.value,
                TaskDesc: issueData.taskData?.label,
                WorkingLocation: navigateData.params.workingLocation,
                SubWorkingLocation: navigateData.params.subWorkingLocation,
                ProblemLocation: navigateData.params.problemLocation,
                TreatmentPoint: navigateData.params.treatmentPoint,
                StartDate: dateFormat(issueData.workedDate, issueData.workedTimeStart),
                EndDate: dateFormat(issueData.workedDate, issueData.workedTimeEnd),
                IsLunchIncluded: issueData.isLunchIncluded,
                IsCompleted: issueData.isCompleted,
                IssueItems: objectIssueItems,
                IssueResources: objectResourceItems,
                Description: issueData.description,
                IssueImages: images,
                ProduceAmount: 0,
                UserId: employee.value,
                isNightShift: isNightShift,
                isDraft: isDraft,
            };
            if (isEndOfDayChecked && !isEndOfDayWaning) {
                ApiClientService.post(
                    process.env.REACT_APP_API_WORKED_DATE_ISSUE,
                    object
                ).then((value) => {
                    var data = value.data.value;
                    if (!data.isValid) {
                        var dialog = { ...dialogSettings };
                        dialog.endOfDay.message = data.message;
                        setDialogSettings(dialog);
                        dialogOpenReject("endofday", true);
                    } else postAction(object);
                });
            } else postAction(object);
        } catch (e) {
            handleAlert("Please fill in the blanks", "warning");
        }
    };
    const postAction = (object) => {
        ApiClientService.post(process.env.REACT_APP_API_CREATE_ISSUE, object).then(
            (value) => {
                if (value.status == 200 || value.status == 201 || value.status == 204) {
                    handleAlert("Update process successful.", "success");
                    if (object.Id != 0)
                        setTimeout(() => history.push("/approve-waiting-activities"), 2000);
                    else if (object.isDraft)
                        setTimeout(() => history.push("/my-pending-activities"), 2000);
                    else setTimeout(() => history.push("/assigned-activities"), 2000);
                } else {
                    handleAlert("An error occurred.", "error");
                }
            }
        );
    };
    const isShowDraftButton = () => {
        if (issueData.isDraft || navigateData.params.action == "add") return true;
        return false;
    };
    const endOfDaySettings = (e) => {
        //Gün sonu kontrolünden onay alındığında bu değişken true olur.
        isEndOfDayWaning = true;
        imageUpload(e, true);
        dialogOpenReject("endofday", false);
    };
    var activityContextData = {
        resources,
        setResources,
        itemOptions,
        setItemOptions,
        batchNumbers,
        setBatchNumbers,
        items,
        setItems,
        itemSets,
        setItemSets,
        issue: navigateData.params,
        docStgId: navigateData.params.docStgId,
        action: navigateData.params.action,
    };
    return (
        <ActivityContext.Provider value={activityContextData}>
            <Spin spinning={loading} tip="loading" key={"page-spin"}>
                <div className="container">
                    <UserDialog
                        open={dialogSettings.postAction.open}
                        title={dialogSettings.postAction.title}
                        body={dialogSettings.postAction.message}
                        approveAction={imageUpload}
                        rejectAction={rejectAction}
                    ></UserDialog>

                    <UserDialog
                        open={dialogSettings.endOfDay.open}
                        title={dialogSettings.endOfDay.title}
                        body={dialogSettings.endOfDay.message}
                        approveAction={endOfDaySettings}
                        rejectAction={rejectAction}
                    ></UserDialog>
                    <UserDialog
                        open={dialogSettings.postDraft.open}
                        title={dialogSettings.postDraft.title}
                        body={dialogSettings.postDraft.message}
                        approveAction={imageUpload}
                        rejectAction={rejectAction}
                    ></UserDialog>
                    <UserSnackbars
                        open={openSnackbar}
                        variant={variantSnackbar}
                        message={messageSnackbar}
                        timeout={timeout}
                        handleClose={closeSnackbar}
                    ></UserSnackbars>
                    <div className="row" key={"issue-main-div"}>
                        <div className="col-12">
                            <div className="card p-5" key={"issue-key"}>
                                <form className="needs-validation" key="issue-form">
                                    {employeeSelectBox()}
                                    <div className="form-row">
                                        <div className="col-12 col-sm-4 col-lg-4 col-xl-2 mb-3" key={"issue-worked-time-div"}>
                                            <label>Worked Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="workedDate"
                                                min={minDate}
                                                max={maxDate}
                                                disabled={isDisabled}
                                                value={issueData.workedDate}
                                                onChange={(e) =>
                                                    setIssueData({
                                                        ...issueData,
                                                        workedDate: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                        </div>
                                        <div className="col-6 col-sm-4 col-lg-4 col-xl-2" key={"issue-start-time-div"}>
                                            <label>Start Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                id="workedTimeStart"
                                                defaultValue={issueData.workedTimeStart}
                                                onChange={(e) =>
                                                    setIssueData({
                                                        ...issueData,
                                                        workedTimeStart: e.target.value,
                                                    })
                                                }
                                                placeholder="08.00"
                                            />
                                        </div>
                                        <div className="col-6 col-sm-4 col-lg-4 col-xl-2">
                                            <label>End Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                id="workedTimeEnd"
                                                placeholder="18:00"
                                                defaultValue={issueData.workedTimeEnd}
                                                onChange={(e) =>
                                                    setIssueData({
                                                        ...issueData,
                                                        workedTimeEnd: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div
                                            className="col-md-6 mt-3 align-items-center p-5"
                                            style={{ display: "grid" }}
                                            key={"custom-checkbox"}
                                        >
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-check">
                                                        <CustomCheckBox
                                                            value={issueData.isLunchIncluded}
                                                            single={false}
                                                            name="isLunchIncluded"
                                                            data={issueData}
                                                            setValue={setIssueData}
                                                            label="Lunch Break?"
                                                            color="secondary"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-check">
                                                        <CustomCheckBox
                                                            value={issueData.isCompleted}
                                                            single={false}
                                                            name="isCompleted"
                                                            data={issueData}
                                                            setValue={setIssueData}
                                                            label="Is the Work Completed?"
                                                            color="secondary"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-check">
                                                        <CustomCheckBox
                                                            value={isEndOfDayChecked}
                                                            single={true}
                                                            name="EndOfDay"
                                                            setValue={setIsEndOfDayChecked}
                                                            label="End of the day?"
                                                            color="default"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-check">
                                                        <CustomCheckBox
                                                            value={isNightShift}
                                                            single={true}
                                                            name="NightShift"
                                                            setValue={setIsNightShift}
                                                            label="Night Shift"
                                                            color="default"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-row">
                                        <div className="col-12 mb-3">
                                            <ItemComponent key={"material-item-key"} />
                                        </div>
                                    </div>

                                    <hr />
                                    <div className="form-row">
                                        <div className="col-12 mb-3">
                                            <ItemSetComponent issueSetItems={issueSetItems} key="material-item-set-key" />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-row">
                                        <div className="col-12 mb-3">
                                            <ResourceComponent key="resource-ekipman-key" />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-row" key={"task-div"}>
                                        <div className="col-12 col-md-6 col-xl-6">
                                            <label>Task</label>
                                            <Select
                                                value={issueData.taskData}
                                                key="task-select-box"
                                                onChange={(e) =>
                                                    setIssueData({ ...issueData, taskData: e })
                                                }
                                                options={taskOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-12 col-xl-6">
                                            <label>Remarks</label>
                                            <Input
                                                type="textarea"
                                                key="remarks-text-area"
                                                name="text"
                                                style={textAreaStyle}
                                                value={issueData.description}
                                                onChange={(e) =>
                                                    setIssueData({
                                                        ...issueData,
                                                        description: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-12">
                                            <label>Image</label>
                                            <ImageUpload
                                                setFile={setFile}
                                                file={file}
                                                imageTagOptions={imageTagOptions}
                                                isShow={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mt-3"
                                            onClick={(e) => openDialog(e, "save")}
                                        >
                                            Save
                                        </button>
                                        {isShowDraftButton() == true ? (
                                            <button
                                                type="submit"
                                                className="btn btn-warning mt-3 ml-3"
                                                onClick={(e) => openDialog(e, "draft")}
                                                disabled={isDisabled}
                                            >
                                                Draft Save
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </ActivityContext.Provider>
    );
}
export default ActivityAddEdit;
